<template>
  <div class="sm:px-6 lg:px-10 xl:px-15 py-6 w-full bg-promy-gray-120">
    <div class="flex-flex-col">
      <div class="flex sm:flex-col-reverse justify-between items-start">
        <div class="flex flex-col w-full">
          <div
            class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
          >
            <span class="text-promy-gray-650">Tableau de bord:</span>
          </div>
          <div class="content-dashbord lg:space-y-16">
            <div
              class="item-dashbord lg:w-full w-1/2 lg:pr-0 pr-3 flex flex-col"
            >
              <div
                class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-6"
              >
                Dossiers promoteurs actifs
              </div>
              <div
                class="flex flex-col flex-1 rounded-lg shadow-lg w-full bg-promy-gray-120 pb-1 table-content"
              >
                <div
                  class="flex justify-between rounded-t-lg bg-white text-sm font-extrabold text-promy-gray-525 px-3 py-4 shadow-lg z-10"
                >
                  <span>Total dossiers promoteur</span>
                  <span class="text-promy-gray-600">{{
                    dossierEnCommercialisation.meta.total
                  }}</span>
                </div>
                <div class="bg-promy-gray-120 relative flex-1 px-2">
                  <dashboard-table
                    :value="dossierEnCommercialisation.data"
                    :last_page="dossierEnCommercialisation.meta.last_page"
                    class="scroll-item h-full absolute px-2 left-0 right-0"
                    :type="'enCommercialisation'"
                  >
                    <dashboard-table-row
                      slot="pro-table-row"
                      slot-scope="{ item }"
                      gridCols="4"
                    >
                      <pro-table-cell class="col-span-2 sm:col-span-1">
                        <span
                          class="text-gray-700 text-sm font-extrabold sm:text-center w-full"
                        >
                          {{ item.adresse }}
                        </span>
                      </pro-table-cell>

                      <pro-table-cell class="mx-auto sm:mr-auto sm:ml-0">
                        <span class="text-gray-700 text-sm sm:text-center">
                          {{ item.code_postal }}</span
                        >
                      </pro-table-cell>

                      <pro-table-cell class="ml-auto sm:mr-auto sm:ml-0">
                        <div class="flex ml-10 sm:ml-0">
                          <router-link
                            :to="`/dossier-promoteurs/informations/${item.id}`"
                            class="bg-promy-green-300 rounded-full p-2 cursor-pointer sm:flex sm:items-center sm:px-3 sm:my-2"
                          >
                            <img
                              src="/images/update.svg"
                              alt=""
                              class="w-4 sm:mr-2"
                            />
                            <span class="hidden sm:inline-block text-white"
                              >Editer</span
                            >
                          </router-link>
                        </div>
                      </pro-table-cell>
                    </dashboard-table-row>
                  </dashboard-table>
                </div>
              </div>
            </div>
            <div
              class="item-dashbord lg:w-full w-1/2 lg:pl-0 xl:pr-3 flex flex-col"
            >
              <div
                class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-6"
              >
                Dossiers promoteurs en cours
              </div>
              <div
                class="flex flex-col flex-1 rounded-lg shadow-lg w-full bg-promy-gray-120 pb-1 table-content"
              >
                <div
                  class="flex justify-between rounded-t-lg bg-white text-sm font-extrabold text-promy-gray-525 px-3 py-4 shadow-lg z-10"
                >
                  <span>Total dossiers en cours</span>
                  <span class="text-promy-gray-600">{{
                    dossierEnBrouillon.meta.total
                  }}</span>
                </div>
                <div class="bg-promy-gray-120 relative flex-1 px-2">
                  <dashboard-table
                    :value="dossierEnBrouillon.data"
                    :last_page="dossierEnBrouillon.meta.last_page"
                    class="scroll-item h-full absolute px-2 left-0 right-0"
                    :type="'enBrouillon'"
                  >
                    <dashboard-table-row
                      slot="pro-table-row"
                      slot-scope="{ item }"
                      gridCols="4"
                    >
                      <pro-table-cell class="col-span-2 sm:col-span-1">
                        <span
                          class="text-gray-700 text-sm font-extrabold w-96 sm:w-68 sm:text-center"
                        >
                          {{ item.adresse }}
                        </span>
                      </pro-table-cell>

                      <pro-table-cell class="justify-center">
                        <span
                          class="text-gray-700 text-sm mx-auto sm:mr-auto sm:ml-0"
                        >
                          {{ item.code_postal }}
                        </span></pro-table-cell
                      >
                      <pro-table-cell class="ml-auto sm:mr-auto sm:ml-0">
                        <div class="flex ml-10 sm:ml-0">
                          <router-link
                            :to="`/dossier-promoteurs/informations/${item.id}`"
                            class="bg-promy-green-300 rounded-full p-2 cursor-pointer sm:flex sm:items-center sm:px-3 sm:my-2"
                          >
                            <img
                              src="/images/update.svg"
                              alt=""
                              class="w-4 sm:mr-2"
                            />
                            <span class="hidden sm:inline-block text-white"
                              >Editer</span
                            >
                          </router-link>
                        </div>
                      </pro-table-cell>
                    </dashboard-table-row>
                  </dashboard-table>
                </div>
              </div>
            </div>
            <div
              class="item-dashbord lg:w-full w-1/2 lg:pl-0 xl:pr-3 flex flex-col"
            >
              <div
                class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-6"
              >
                Dernières enchères en cours
              </div>
              <div
                class="flex flex-col flex-1 rounded-lg shadow-lg w-full bg-promy-gray-120 pb-1 table-content"
              >
                <div
                  class="flex justify-between rounded-t-lg bg-white text-sm font-extrabold text-promy-gray-525 px-3 py-4 shadow-lg z-10"
                >
                  <span>Total Offre en cours</span>
                  <span class="text-promy-gray-600">{{
                    offreEnCours.meta.total
                  }}</span>
                </div>
                <div class="bg-promy-gray-120 relative flex-1 px-2">
                  <dashboard-table
                    :value="offreEnCours.data"
                    :last_page="offreEnCours.meta.last_page"
                    class="scroll-item h-full absolute px-2 left-0 right-0"
                    :type="'offre'"
                  >
                    <dashboard-table-row
                      slot="pro-table-row"
                      slot-scope="{ item }"
                      gridCols="5"
                    >
                      <pro-table-cell class="col-span-2 sm:col-span-1">
                        <span class="text-gray-700 text-sm font-extrabold">
                          {{ item.adresse }}
                        </span>
                      </pro-table-cell>

                      <pro-table-cell
                        class="text-gray-700 text-base sm:text-center"
                      >
                        {{ item.prix | formatEUR }}€
                      </pro-table-cell>
                      <pro-table-cell
                        class="text-gray-700 text-base sm:text-center"
                      >
                        {{ item.status }}
                      </pro-table-cell>
                      <pro-table-cell class="justify-end !ml-auto">
                        <div class="flex ml-10 sm:ml-0">
                          <router-link
                            :to="`/dossiers-promoteurs/dossier-promoteurs/${item.terrain_id}/offres/${item.hash_id}`"
                            class="bg-promy-green-300 rounded-full grid place-items-center p-2 cursor-pointer sm:flex sm:items-center sm:px-3 sm:my-2"
                          >
                            <i
                              class="fas fa-eye text-white text-xs sm:mr-1"
                            ></i>
                            <span class="hidden sm:inline-block text-white"
                              >Voir l'offre</span
                            >
                          </router-link>
                        </div>
                      </pro-table-cell>
                    </dashboard-table-row>
                  </dashboard-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      auction: Array.from({ length: 10 }, (_, idx) => {
        return {
          name: `Enchère ${++idx}`,
          value: 'lorem',
        }
      }),
    }
  },
  computed: {
    ...mapGetters({
      dossierEnCommercialisation: 'dashboard/dossierEnCommercialisation',
      dossierEnBrouillon: 'dashboard/dossierEnBrouillon',
      offreEnCours: 'dashboard/offreEnCours',
      isSet: 'dashboard/isSet',
    }),
  },
  mounted() {
    this.$store.commit('dashboard/SET_DEFAULT')
    if (this.isSet) return
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/getDashboard')
    },
  },
}
</script>
<style lang="scss" scoped>
.content-dashbord {
  @apply w-full flex flex-wrap;
  height: 75vh;

  @screen lg {
    height: 100%;
  }
}

.item-dashbord {
  @apply pb-6;
  height: 50%;

  @screen sm {
    height: 75vh;
  }

  @screen md {
    height: 50vh;
  }
}

.table-content {
  @screen lg {
    height: 40vh;
  }
}

.scroll-item {
  @apply overflow-y-scroll;
}

.scroll-item::-webkit-scrollbar {
  width: 0.3em;
}

.scroll-item::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #828181;
}

.scroll-item::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #eeeeee;
}
</style>
